import React, { useState } from 'react';

const Calculator = () => {
    const [input, setInput] = useState('0');
    const [lastInput, setLastInput] = useState('');

    const handleButtonClick = (value) => {
        if (value === 'AC') {
            setInput('0');
            setLastInput('');
            return;
        }

        if (value === '=') {
            try {
                const result = evaluateExpression(input);
                setInput(result.toString());
            } catch (error) {
                setInput('Error');
            }
            return;
        }

        if (value === '.' && lastInput.includes('.')) {
            return;
        }

        if (/[*+/-]/.test(value) && /[*+/-]/.test(lastInput)) {
            const newInput = input.slice(0, -1) + value;
            setInput(newInput);
        } else {
            setInput((prevInput) => (prevInput === '0' ? value : prevInput + value));
        }

        setLastInput(value);
    };

    const evaluateExpression = (expression) => {
        const operators = [];
        const values = [];
        let i = 0;

        while (i < expression.length) {
            if (!isNaN(expression[i])) {
                let num = '';
                while (i < expression.length && (!isNaN(expression[i]) || expression[i] === '.')) {
                    num += expression[i++];
                }
                values.push(parseFloat(num));
            } else if (expression[i] === '(') {
                operators.push(expression[i++]);
            } else if (expression[i] === ')') {
                while (operators.length && operators[operators.length - 1] !== '(') {
                    values.push(applyOperator(operators.pop(), values.pop(), values.pop()));
                }
                operators.pop();
                i++;
            } else if (isOperator(expression[i])) {
                while (operators.length && precedence(operators[operators.length - 1]) >= precedence(expression[i])) {
                    values.push(applyOperator(operators.pop(), values.pop(), values.pop()));
                }
                operators.push(expression[i++]);
            }
        }

        while (operators.length) {
            values.push(applyOperator(operators.pop(), values.pop(), values.pop()));
        }

        return values.pop();
    };

    const isOperator = (char) => {
        return ['+', '-', '*', '/'].includes(char);
    };

    const precedence = (operator) => {
        switch (operator) {
            case '+':
            case '-':
                return 1;
            case '*':
            case '/':
                return 2;
            default:
                return 0;
        }
    };

    const applyOperator = (operator, b, a) => {
        switch (operator) {
            case '+':
                return a + b;
            case '-':
                return a - b;
            case '*':
                return a * b;
            case '/':
                return a / b;
            default:
                return 0;
        }
    };

    const buttons = [
        'AC', '/', '*', '-',
        '9', '8', '7', '+',
        '6', '5', '4', '.',
        '3', '2', '1', '0',
        '='
    ];

    return (
        <div id="calculator">
            <div id="display">{input}</div>
            <div className="buttons">
                {buttons.map((btn) => (
                    <button
                        key={btn}
                        onClick={() => handleButtonClick(btn)}
                        className={btn === '=' ? 'equals' : ''}
                    >
                        {btn}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default Calculator;

